/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { CUSTOMER } from 'src/app/_interfaces/customer.interface'
import {MenuComponent} from 'src/marketConnect/assets/ts/components'
import {ID, KTIcon} from 'src/marketConnect/helpers'
import { setSelectedCustomerForReport } from 'src/redux/slices/customerSlice'
import { useAppDispatch } from 'src/redux/storeHooks'

type Props = {
  // customerDetail:CUSTOMER
  // updateCustomerStatus : ()=>void
  // onLoginAsCustomer : ()=> void
}

const AckActionBtn: FC<Props> = ({ }) => {


  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm fw-bold'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
          <button className='btn btn-sm fw-bold fs-8 p-2 w-100 menu-link px-3 text-decoration-none'>View</button>
        </div> */}
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3 align-self-start'>
          <button className='btn btn-sm fw-bold fs-8 p-2 w-100 menu-link px-3 text-decoration-none' >
            View Order
          </button>
        </div>
        <div className='menu-item px-3 align-self-start'>
          <button className='btn btn-sm fw-bold fs-8 p-2 w-100 menu-link px-3 text-decoration-none text-start'>
            View Customer
          </button>
        </div>
        <div className='menu-item px-3 align-self-start'>
         {/* <Link to={`/reports/${customerDetail.id}`} className='menu-link fs-8 px-3 text-decoration-none'>
            Report
          </Link> */}
         
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {AckActionBtn}
