import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from 'src/marketConnect/helpers'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Platforms from '../../../listing/Platforms'
import {Spinner} from 'react-bootstrap'
import Loader from 'src/utils/Loader'
import {Platform, PlatformDataType} from 'src/interfaces/Platform.interface'
import useAppToast from 'src/hooks/useAppToast'
import {useNavigate} from 'react-router-dom'
import {
  connectPlatformAsync,
  fetchConnectedPlatformAsync,
  fetchPlatformsAsync,
} from 'src/redux/actions/platformActions'
import ConnectPlatformModal from '../ConnectPlatformModal'
import PlatformCard from '../PlatformCard'
import clsx from 'clsx'

type StepStatus = 'active' | 'inactive' | 'finished'

type TourSteps = {
  stepOne: StepStatus
  stepTwo: StepStatus
  stepThree: StepStatus
}

type TourStep1PropType = {
  setStepsData: React.Dispatch<React.SetStateAction<TourSteps>>
  setSelectedPlatformId: React.Dispatch<React.SetStateAction<string | undefined>>
}

const TourStep1: FC<TourStep1PropType> = ({setStepsData, setSelectedPlatformId}) => {
  const {fetchedConnectedPlatformData, fetchedConnectedPlatformLoading} = useAppSelector(
    (state) => state.fetchConnectedPlatform
  )
  const {shopifyConnectFirstTimeMessageShow, firstTimeMessage} = useAppSelector(
    (state) => state.auth
  )
  const {fetchedUserAccountData} = useAppSelector((state) => state.userAccountData)
  const {platformData} = useAppSelector((state) => state.platform)
  const {connectedPlatformData} = useAppSelector((state) => state.connectedPlatform)
  const [showConnectPlatformModal, setShowConnectPlatformModal] = useState<boolean>(false)
  const [selectedPlatform, setSelectedPlatform] = useState<Platform | null>(null)
  const [ecommercePlatform, setEcommercePlatform] = useState<any>([])
  const [showSettingModal, setShowSettingModal] = useState<boolean>(false)
  const [modalData, setModalData] = useState<PlatformDataType>()
  const [connectingPlatformId, setConnectingPlatformId] = useState<{
    platformId: number | string | null
    isConnecting: boolean
  }>({isConnecting: false, platformId: null})
   

  useEffect(() => {
    if (fetchedConnectedPlatformData.length === 0) {
      let newPlatformData = platformData?.filter((ele) =>
        ['ECOMMERCE', 'SHIPPING_PLATFORM'].includes(ele.type)
      )
      setEcommercePlatform(newPlatformData)
    } else {
      setEcommercePlatform(platformData)
    }
  }, [])

  

  return (
    <>
      <div>
        <h3>
          {connectingPlatformId.isConnecting
            ? ''
            : 'Available Primary Channels'}
        </h3>
      </div>
      <div className='py-4'>
        <div
          className={clsx(
            'row g-3 gx-2 g-xl-8 my-2',
            connectingPlatformId.isConnecting ? 'justify-content-center' : ''
          )}
        >
          {ecommercePlatform?.length > 0 &&
            ecommercePlatform.map((item: Platform) => {
              if (connectingPlatformId.isConnecting) {
                if (connectingPlatformId.platformId === item.id) {
                  return (
                    <PlatformCard
                      key={item.id}
                      data={item}
                      onClickConnectTop={setConnectingPlatformId}
                      setStepsData={setStepsData}
                      setSelectedPlatformId={setSelectedPlatformId}
                    />
                  )
                }
              } else {
                return (
                  <PlatformCard
                    key={item.id}
                    data={item}
                    onClickConnectTop={setConnectingPlatformId}
                    setStepsData={setStepsData}
                    setSelectedPlatformId={setSelectedPlatformId}
                  />
                )
              }
            })}
        </div>
      </div>
      {/* { showConnectPlatformModal ? 
        <ConnectPlatformModal
        isVisible={showConnectPlatformModal}
        closeModal={handleCloseModal}
        data={selectedPlatform}
        onDataReceived={handleDataReceived} // Pass the data receiving function
      /> : null } */}
    </>
  )
}

export default TourStep1
