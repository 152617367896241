// import React, {FC, Fragment, useEffect, useState} from 'react'
// import {Alert, Button, Modal, Stack} from 'react-bootstrap'
// import AppCustomEditor from 'src/utils/CustomEditor'
// import {PlatformDataType} from 'src/interfaces/Platform.interface'
// import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
// import {toast} from 'react-toastify'
// import {
//   getShortCodeAsync,
//   savePlatformTitleDescriptionTemplateAsync,
//   updateTitleDescriptionTemplateAsync,
// } from 'src/redux/actions/templateAndPricingActions'
// import useAppToast from 'src/hooks/useAppToast'
// import CopyToClipboard from 'react-copy-to-clipboard'
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faPlus} from '@fortawesome/free-solid-svg-icons'
// type AddNewTitleDescriptionModalPropsType = {
//   isVisible: boolean
//   platformData: PlatformDataType | undefined
//   closeModal: () => void
//   // selectedTemplateData: any
//   // setIsCheckedtitle: React.Dispatch<React.SetStateAction<boolean>>
//   // isCheckedtitle: boolean
//   // setSelectedTemplateData: any
//   updateItem:any|null
//   getLatestTitleDescriptionTemplateList:()=>void
// }
// const AddNewTitleDescriptionModal: FC<AddNewTitleDescriptionModalPropsType> = ({
//   isVisible,
//   platformData,
//   closeModal,
//   // selectedTemplateData,
//   // setIsCheckedtitle,
//   // isCheckedtitle,
//   updateItem,
//   // setSelectedTemplateData,
//   getLatestTitleDescriptionTemplateList,
// }) => {
//   const [editorData, setEditorData] = useState<string>('')
//   const [showIsDefault, setShowIsDefault] = useState<boolean>(false)
//   const [titleInput, setTitleInput] = useState('') // State for Title input
//   const [tagsInput, setTagsInput] = useState('') // State for Tags input
//   const [skuInput, setSkuInput] = useState('') // State for Sku input
//   const [nameInput, setNameInput] = useState('')
//   const {successToast, errorToast} = useAppToast()
//   const [isShortCodeVisible, setShortCodeVisible] = useState(false)
//   const [copyMarkup, setCopyMarkup] = useState<{[key: string]: boolean}>({})
//   const [buttonString, setButtonString] = useState('')
//   const [shortCode, setShortCode] = useState([])
//   // State to track the visibility of each set of short codes
//   const [shortCodeVisibility, setShortCodeVisibility] = useState({
//     name: false,
//     title: false,
//     tags: false,
//     sku: false,
//     description:false,
//   })

//   // Function to toggle the visibility of short codes for a specific input
//   const toggleShortCodeVisibility = (input: string) => {
//     setShortCodeVisibility((prevVisibility) => ({
//       ...prevVisibility,
//       [input]: !prevVisibility[input],
//     }))
//   }

//   const dispatch = useAppDispatch()
//   console.log('data in modal', platformData)

//   const createPayload = (): object => {
//     return {
//       platform_id: platformData, // Use platform ID if available, otherwise default to 0
//       name: nameInput,
//       title_value_string: titleInput,
//       description_value_string: editorData,
//       tags_value_string: tagsInput,
//       sku_value_string: skuInput,
//       isDefault: showIsDefault,
//     }
//   }

//   // Modified handleShortCode function to accept input identifier
//   const handleShortCode = async (input: string) => {
//     try {
//       dispatch(getShortCodeAsync({shortcode_type: 'PRODUCT_SC', search_string: ''})).then(
//         (response) => {
//           if (response.payload) {
//             setShortCode(response.payload?.results)
//             toggleShortCodeVisibility(input) // Toggle visibility for the clicked input
//           }
//         }
//       )
//     } catch (error) {
//       console.error('Error fetching short codes:', error)
//     }
//   }

//   const updateTemplate = async () => {
//     const payloadId = createPayload()
//     console.log('payload fromm modal:', payloadId)
//     setSelectedTemplateData('')
//     try {
//       await dispatch(
//         updateTitleDescriptionTemplateAsync({
//           platform_id: String(selectedTemplateData?.id),
//           payload: payloadId,
//         })
//       ).then((response) => {
//         successToast('Updated Successfully')

//         setNameInput('')
//         setSkuInput('')
//         setTagsInput('')
//         setShowIsDefault(false)
//         setTitleInput('')
//         setEditorData('')
//       })
//     } catch (error) {
//       console.log('error', error)
//     }
//   }

//   const addTemplate = async () => {
//     const payload = createPayload()
//     setSelectedTemplateData('')
//     try {
//       await dispatch(savePlatformTitleDescriptionTemplateAsync(payload)).then((response) => {
//         successToast('Added Successfully')

//         setNameInput('')
//         setSkuInput('')
//         setTagsInput('')
//         setShowIsDefault(false)
//         setTitleInput('')
//         setEditorData('')
//       })
//     } catch (error) {
//       console.log('error', error)
//     }
//   }

//   // const handleCopyMarkup = (data: string) => {
//   //   setCopyMarkup(true)
//   //   setButtonString(data)

//   //   setTimeout(() => {
//   //     setCopyMarkup(false)
//   //     setButtonString('')
//   //   }, 1000)
//   // }
//   // Function to handle copy action and set copied status for the specific shortcode
//   const handleCopyMarkup = (data: string, identifier: string) => {
//     setCopyMarkup((prevStatus) => ({
//       ...prevStatus,
//       [identifier]: true, // Set copied status for the specific shortcode
//     }))

//     setButtonString(data)

//     setTimeout(() => {
//       setCopyMarkup((prevStatus) => ({
//         ...prevStatus,
//         [identifier]: false, // Reset copied status after a timeout
//       }))
//       setButtonString('')
//     }, 1000)
//   }

//   const handleSave = async () => {
//     setIsCheckedtitle(!isCheckedtitle)
//     {
//       selectedTemplateData?.id ? updateTemplate() : addTemplate()
//     }

//     closeModal()
//   }

//   useEffect(() => {
//     if (selectedTemplateData) {
//       setNameInput(selectedTemplateData?.name)
//       setTitleInput(selectedTemplateData?.title_value_str)
//       setTagsInput(selectedTemplateData?.tags_value_str)
//       setSkuInput(selectedTemplateData?.sku_value_str)
//     }
//   }, [selectedTemplateData])

//   return (
//     <>
//       <Modal size={'lg'} centered show={isVisible} onHide={closeModal}>
//         <Modal.Header>
//           {selectedTemplateData?.id ? (
//             <Modal.Title> Update Mapping Rule for  Title and Others </Modal.Title>
//           ) : (
//             <Modal.Title>Add New Mapping Rule for  Title and Others</Modal.Title>
//           )}

//           <div className='d-inline-flex'>
//             <Button
//               size='sm'
//               className='mx-2'
//               onClick={() => {
//                 closeModal()
//                 setSelectedTemplateData('')
//               }}
//             >
//               Close
//             </Button>
//             <Button size='sm' variant='success' onClick={handleSave}>
//               Save Changes
//             </Button>
//           </div>
//         </Modal.Header>
//         <Modal.Body>
//           {/* <div className='p-2 d-flex mb-8 border rounded flex-row align-items-center'>
//             <p className='form-label mb-0'>Platform Name</p>
//             <div className='ms-8 d-flex flex-row justify-content-center align-items-center'>
//               <img
//                 src='https://theme.zdassets.com/theme_assets/11606116/c271c0edf9baeb714db2ccef96cbd6d86a0a296f.png'
//                 width={'30px'}
//                 height={'30px'}
//               />
//               <p className='h4 mb-0 text-success ms-4'>Test Platform</p>
//             </div>
//           </div> */}

//           {/* modifications */}
//           {/* <div className='mb-8'>
//             <div className='d-flex align-items-center flex-row justify-content-center justify-content-around'>
//             <label className='form-label align-items-center '>Name</label>
//             <input
//               type='text'
//               className='form-control w-60 ms-2'
//               placeholder={selectedTemplateData ? selectedTemplateData?.name : 'Enter Name'}
//               onChange={(e) => setNameInput(e.target.value)}
//               value={nameInput} // Set input value from state
//             />
            
//             </div>
//             <div className='text-end '>
//               <p
//                 className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
//                 onClick={() => handleShortCode('name')} // Pass input identifier to handleShortCode
//               >
//                  <FontAwesomeIcon icon={faPlus} className='me-2' /> See the Options here
//               </p>
//             </div>
            
            
//             {shortCodeVisibility.name ? (
//               <div className='text-end me-5 mt-2'>
//                 {shortCode
//                   ? shortCode.map((item: any) => {
//                       return (
//                         <Fragment key={item.id}>
//                           <CopyToClipboard text={item.name}>
//                             <Button
//                               size='sm'
//                               variant={
//                                 copyMarkup['name'] && buttonString === item.name
//                                   ? 'outline-success'
//                                   : 'outline-primary'
//                               }
//                               className={`px-3 py-2 border border-1 m-2 ${
//                                 copyMarkup['name'] && buttonString === item.name
//                                   ? 'border-success'
//                                   : 'border-primary'
//                               } rounded text-hover-dark fs-sm-8 inline-block`}
//                               onClick={() => handleCopyMarkup(item.name, 'name')}
//                             >
//                               {copyMarkup['name'] && buttonString === item.name ? (
//                                 <span className='fs-sm-8'>Copied !</span>
//                               ) : (
//                                 item.name
//                               )}
//                             </Button>
//                           </CopyToClipboard>
//                         </Fragment>
//                       )
//                     })
//                   : null}
//               </div>
//             ) : null}
//           </div> */}

//           <div className='mb-8'>
//             <label className='form-label'>Rule Name</label>
//             <input
//               type='text'
//               className='form-control'
//               placeholder={'Enter Name'}
//               onChange={(e) => setNameInput(e.target.value)}
//               value={nameInput} // Set input value from state
//             />
          
//           </div>
//           <div className='mb-8'>
//             <label className='form-label'>Title</label>
//             <input
//               type='text'
//               className='form-control'
//               placeholder={'Customize your Title.'}
//               // Update titleInput state on change
//               onChange={(e) => setTitleInput(e.target.value)}
//               value={titleInput} // Set input value from state
//             />
//             <div className='text-end'>
//               <p
//                 className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
//                 onClick={() => handleShortCode('title')} // Pass input identifier to handleShortCode
//               >
//                 <FontAwesomeIcon icon={faPlus} className='me-2' /> See the Options here
//               </p>
//             </div>
//             {shortCodeVisibility.title ? (
//               <div className='text-end'>
//                 {shortCode
//                   ? shortCode.map((item: any) => {
//                       return (
//                         <Fragment key={item.id}>
//                           <CopyToClipboard text={item.name}>
//                             <Button
//                               size='sm'
//                               variant={
//                                 copyMarkup['title'] && buttonString === item.name
//                                   ? 'outline-success'
//                                   : 'outline-primary'
//                               }
//                               className={`px-3 py-2 border border-1 m-2 ${
//                                 copyMarkup['title'] && buttonString === item.name
//                                   ? 'border-success'
//                                   : 'border-primary'
//                               } rounded text-hover-dark fs-sm-8 inline-block`}
//                               onClick={() => handleCopyMarkup(item.name, 'title')}
//                             >
//                               {copyMarkup['title'] && buttonString === item.name ? (
//                                 <span className='fs-sm-8'>Copied !</span>
//                               ) : (
//                                 item.name
//                               )}
//                             </Button>
//                           </CopyToClipboard>
//                         </Fragment>
//                       )
//                     })
//                   : null}
//               </div>
//             ) : null}
//           </div>
//           <div>
//             <label className='form-label'>Description</label>
//             <AppCustomEditor value={editorData} onChangeData={setEditorData} />
//             <div className='text-end'>
//               <p
//                 className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
//                 onClick={() => handleShortCode('description')} // Pass input identifier to handleShortCode}
//               >
//                 <FontAwesomeIcon icon={faPlus} className='me-2' /> See the Options here
//               </p>
//             </div>
//             {shortCodeVisibility.description ? (
//               <div className='text-end'>
//                 {shortCode
//                   ? shortCode.map((item: any) => {
//                       return (
//                         <Fragment key={item.id}>
//                           <CopyToClipboard text={item.name}>
//                             <Button
//                               size='sm'
//                               variant={
//                                 copyMarkup['description'] && buttonString === item.name
//                                   ? 'outline-success'
//                                   : 'outline-primary'
//                               }
//                               className={`px-3 py-2 border border-1 m-2 ${
//                                 copyMarkup['description'] && buttonString === item.name
//                                   ? 'border-success'
//                                   : 'border-primary'
//                               } rounded text-hover-dark fs-sm-8 inline-block`}
//                               onClick={() => handleCopyMarkup(item.name, 'description')}
//                             >
//                               {copyMarkup['description'] && buttonString === item.name ? (
//                                 <span className='fs-sm-8'>Copied !</span>
//                               ) : (
//                                 item.name
//                               )}
//                             </Button>
//                           </CopyToClipboard>
//                         </Fragment>
//                       )
//                     })
//                   : null}
//               </div>
//             ) : null}
          
//           </div>
//           <div className='mb-8'>
//             <label className='form-label'>Tags</label>
//             <input
//               type='text'
//               className='form-control'
//               placeholder={'Customize or map the Tag Field  as needed.'}
//               // Update tagsInput state on change
//               onChange={(e) => setTagsInput(e.target.value)}
//               value={tagsInput} // Set input value from state
//             />
//             <div className='text-end'>
//               <p
//                 className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
//                 onClick={() => handleShortCode('tags')} // Pass input identifier to handleShortCode}
//               >
//                 <FontAwesomeIcon icon={faPlus} className='me-2' /> See the Options here
//               </p>
//             </div>
//             {shortCodeVisibility.tags ? (
//               <div className='text-end'>
//                 {shortCode
//                   ? shortCode.map((item: any) => {
//                       return (
//                         <Fragment key={item.id}>
//                           <CopyToClipboard text={item.name}>
//                             <Button
//                               size='sm'
//                               variant={
//                                 copyMarkup['tags'] && buttonString === item.name
//                                   ? 'outline-success'
//                                   : 'outline-primary'
//                               }
//                               className={`px-3 py-2 border border-1 m-2 ${
//                                 copyMarkup['tags'] && buttonString === item.name
//                                   ? 'border-success'
//                                   : 'border-primary'
//                               } rounded text-hover-dark fs-sm-8 inline-block`}
//                               onClick={() => handleCopyMarkup(item.name, 'tags')}
//                             >
//                               {copyMarkup['tags'] && buttonString === item.name ? (
//                                 <span className='fs-sm-8'>Copied !</span>
//                               ) : (
//                                 item.name
//                               )}
//                             </Button>
//                           </CopyToClipboard>
//                         </Fragment>
//                       )
//                     })
//                   : null}
//               </div>
//             ) : null}
//           </div>
//           <div className='mb-8'>
//             <label className='form-label'>Sku</label>
//             <input
//               type='text'
//               className='form-control'
//               placeholder={'Enter Sku'}
//               // Update skuInput state on change
//               onChange={(e) => setSkuInput(e.target.value)}
//               value={skuInput} // Set input value from state
//             />
//             {/* <div className='text-end'>
//               <p
//                 className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer'
//                 onClick={() => handleShortCode('sku')} // Pass input identifier to handleShortCode
//               >
//                 <p>View Short Codes</p>
//               </p>
//             </div> */}
//           </div>
//           <div className=' d-inline-flex form-check form-switch  mt-2 ps-2'>
//             <div>
//               <label className='form-label mt-1'>Default</label>
//             </div>
//             <input
//               className='ms-8 mb-4 form-check-input h-20px w-40px'
//               type='checkbox'
//               checked={selectedTemplateData ? selectedTemplateData?.isDefault : showIsDefault}
//               onChange={() => {
//                 // setShowIsDefault(!showIsDefault)
//                 if (selectedTemplateData) {
//                   // If updateItem exists, update its isDefault property
//                   setSelectedTemplateData((prevItem) => ({
//                     ...prevItem,
//                     isDefault: !prevItem.isDefault,
//                   }))
//                 } else {
//                   // If updateItem doesn't exist, toggle showDefault
//                   setShowIsDefault(!showIsDefault)
//                 }
//               }}
//             />
//           </div>
//         </Modal.Body>
//       </Modal>
//     </>
//   )
// }
// export default AddNewTitleDescriptionModal



import React, { FC, Fragment, useEffect, useState } from 'react';
import { Alert, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import AppCustomEditor from 'src/utils/CustomEditor';
import { PlatformDataType } from 'src/interfaces/Platform.interface';
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
import { toast } from 'react-toastify';
import {
  getShortCodeAsync,
  savePlatformTitleDescriptionTemplateAsync,
  updateTitleDescriptionTemplateAsync,
} from 'src/redux/actions/templateAndPricingActions'
import useAppToast from 'src/hooks/useAppToast'
import CopyToClipboard from 'react-copy-to-clipboard'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import { TITLE_DESCRIPTION, TITLE_DESCRIPTION_TEMPLATE_ITEM } from 'src/interfaces/Template.interface'
import { LANGUAGES_ENUM } from 'src/utils/constants';
type AddNewTitleDescriptionModalPropsType = {
  isVisible: boolean
  closeModal: () => void
  selectedSecondaryPlatform : PlatformDataType
  getLatestTitleDescriptionList : ()=>void
  updateItem : TITLE_DESCRIPTION_TEMPLATE_ITEM|null
}
const AddNewTitleDescriptionModal: FC<AddNewTitleDescriptionModalPropsType> = ({
  isVisible,
  closeModal,
  getLatestTitleDescriptionList,
  selectedSecondaryPlatform,
  updateItem
}) => {
  const [editorData, setEditorData] = useState<string>('');
  const [showIsDefault, setShowIsDefault] = useState<boolean>(false);
  const [titleInput, setTitleInput] = useState(''); 
  const [tagsInput, setTagsInput] = useState(''); 
  const [skuInput, setSkuInput] = useState('');
  const [nameInput, setNameInput] = useState('');
  const { successToast, errorToast } = useAppToast();
  const [openTitleAdvanceConfig, setOpenTitleAdvanceConfig] = useState(false)
  const [openDescriptionAdvanceConfig, setOpenDescriptionAdvanceConfig] = useState(false)
  const [titleAdvanceSetting, setTitleAdvanceSetting] = useState({
    isRemoveHtml: false,
    isTranslate: false,
    translate_language: "",
    isRephrasing: false,
    rephrasing_limit: '',
  })
  const [descriptionAdvanceSetting, setDescriptionAdvanceSetting] = useState({
    isRemoveHtml: false,
    isTranslate: false,
    translate_language: "",
    isRephrasing: false,
    rephrasing_limit: "",
  })

  const [copyMarkup, setCopyMarkup] = useState<{ [key: string]: boolean }>({});
  const [buttonString, setButtonString] = useState('');
  const [shortCode, setShortCode] = useState([]);
  const [shortCodeVisibility, setShortCodeVisibility] = useState({
    name: false,
    title: false,
    tags: false,
    sku: false,
    description: false,
  });
  const[loading,setLoading]=useState<boolean>(false)

  const toggleShortCodeVisibility = (input: string) => {
    setShortCodeVisibility((prevVisibility) => ({
      ...prevVisibility,
      [input]: !prevVisibility[input],
    }));
  };

  const dispatch = useAppDispatch()

  const createPayload = ():TITLE_DESCRIPTION => {
    return {
      platform_id: selectedSecondaryPlatform.id, // Use platform ID if available, otherwise default to 0
      name: nameInput,
      title_value_string: titleInput,
      description_value_string: editorData,
      tags_value_string: tagsInput,
      sku_value_string: skuInput,
      isDefault: showIsDefault,
      title_processing_config: {
        isRemoveHtml: titleAdvanceSetting.isRemoveHtml,
        isTranslate: titleAdvanceSetting.isTranslate,
        translate_language: titleAdvanceSetting.translate_language,
        isRephrasing: titleAdvanceSetting.isRephrasing,
        rephrasing_limit: titleAdvanceSetting.rephrasing_limit,
      },
      description_processing_config: {
        isRemoveHtml: descriptionAdvanceSetting.isRemoveHtml,
        isTranslate: descriptionAdvanceSetting.isTranslate,
        translate_language: descriptionAdvanceSetting.translate_language,
        isRephrasing: descriptionAdvanceSetting.isRephrasing,
        rephrasing_limit: descriptionAdvanceSetting.rephrasing_limit,
      },
    }
  };

  const handleShortCode = async (input: string) => {
    try {
      const response = await dispatch(
        getShortCodeAsync({ shortcode_type: 'PRODUCT_SC', search_string: '' })
      );
      if (response.payload) {
        setShortCode(response.payload?.results);
        toggleShortCodeVisibility(input);
      }
    } catch (error) {
      console.error('Error fetching short codes:', error);
    }
  };

  const updateTemplate = async () => {
    const payloadId = createPayload()
    // setSelectedTemplateData('')
    setLoading(true)
    try {
     dispatch(
        updateTitleDescriptionTemplateAsync({
          platform_id: String(updateItem?.id),
          payload: payloadId,
        })
      ).then((response) => {
        console.log('response', response)
        if(response.payload?.data?.error){
          errorToast(response.payload?.data?.error)
          setLoading(false)
          return
        }
        successToast('Updated Successfully')
        getLatestTitleDescriptionList()
        setNameInput('')
        setSkuInput('')
        setTagsInput('')
        setShowIsDefault(false)
        setTitleInput('')
        setEditorData('')
        setLoading(false)
        closeModal()
      })
    } catch (error) {
      setLoading(false)
      console.log('error', error);
    }
  };

  const addTemplate = async () => {
    const payload = createPayload()
    // setSelectedTemplateData('')
    setLoading(true)
    try {
     dispatch(savePlatformTitleDescriptionTemplateAsync(payload)).then((response) => {
       
      if(response.payload?.data?.error){
        errorToast(response.payload?.data?.error)
        setLoading(false)
        return
      }

        successToast('Added Successfully')
        getLatestTitleDescriptionList()
        setNameInput('')
        setSkuInput('')
        setTagsInput('')
        setShowIsDefault(false)
        setTitleInput('')
        setEditorData('')
        setLoading(false)
        closeModal()
      })
     
    
    } catch (error) {
      setLoading(false)
      console.log('error', error);
    }
  };

  const handleCopyMarkup = (data: string, identifier: string) => {
    setCopyMarkup((prevStatus) => ({
      ...prevStatus,
      [identifier]: true,
    }));

    setButtonString(data);

    setTimeout(() => {
      setCopyMarkup((prevStatus) => ({
        ...prevStatus,
        [identifier]: false,
      }));
      setButtonString('');
    }, 1000);
  };

  const handleSave = async () => {
    // setIsCheckedtitle(!isCheckedtitle)
    
    
    if (updateItem?.id) {
      updateTemplate()
    } else {
      addTemplate()
    }
  }

  useEffect(() => {
    if (updateItem) {
      setNameInput(updateItem?.name)
      setTitleInput(updateItem?.title_value_str)
      setTagsInput(updateItem?.tags_value_str)
      setSkuInput(updateItem?.sku_value_str)
      setShowIsDefault(updateItem.isDefault)
      setEditorData(updateItem.description_value_str)
      console.log('title_processing_config', updateItem.title_processing_config?.isRemoveHtml)
      if(updateItem.title_processing_config){
        setTitleAdvanceSetting(updateItem.title_processing_config)
        if(updateItem.title_processing_config.isRemoveHtml===true||updateItem.title_processing_config.isRephrasing===true||updateItem.title_processing_config.isTranslate===true){
          setOpenTitleAdvanceConfig(true)
        }
      }

      if(updateItem.description_processing_config){
        setDescriptionAdvanceSetting(updateItem.description_processing_config)
        if(updateItem.description_processing_config.isRemoveHtml===true||updateItem.description_processing_config.isRephrasing===true||updateItem.description_processing_config.isTranslate===true){
          setOpenDescriptionAdvanceConfig(true)
        }
      }
    }
  }, [updateItem])

  console.log('advanced options=====>', titleAdvanceSetting.isRemoveHtml );


  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props?.message}
    </Tooltip>
  );

  return (
    <>
      <Modal size={'lg'} backdrop='static' centered show={isVisible} onHide={closeModal}>
        <form onSubmit={(ev)=>{
          ev.preventDefault()
          handleSave()
        }} >
          <Modal.Header>
            {updateItem?.id ? (
              <Modal.Title> Update Mapping Rule for  Title and Others </Modal.Title>
            ) : (
              <Modal.Title>Add New Mapping Rule for  Title and Others</Modal.Title>
            )}

            <div className='d-inline-flex'>
              <Button
                size='sm'
                type='button'
                className='mx-2'
                onClick={() => {
                  setNameInput('')
                  setSkuInput('')
                  setTagsInput('')
                  setShowIsDefault(false)
                  setTitleInput('')
                  setEditorData('')
                  closeModal()
                  // setSelectedTemplateData('')
                }}
                disabled={loading}
              >
                Close
              </Button>
            
              <Button size='sm' type='submit' variant='success' disabled={loading}>
                { loading ? 'Saving...': 'Save Changes'}
              </Button>
            </div>
          </Modal.Header>
          <Modal.Body>
          <div className='d-flex justify-content-end form-check form-switch mt-2 ps-2'>
              <div>
                <label className='form-label mt-1'>Default&nbsp; 
                  <OverlayTrigger placement='left' overlay={(props)=>renderTooltip({...props, message:'Use this Switch when you want all Products to use this Mapping/Rule.'})} >
                    <i className="bi bi-info-circle"></i>
                  </OverlayTrigger> 
                </label>
              </div>
              <input
                className='ms-4 mb-4 form-check-input h-20px w-40px'
                type='checkbox'
                checked={showIsDefault}
                onChange={(ev) => {
                  console.log('ev.target.checked', ev.target.checked)
                setShowIsDefault(ev.target.checked)
                }}
              />
            </div>

            <div className='mb-8'>
              <label className='form-label'>Rule Name</label>
              <input
                type='text'
                className='form-control'
                placeholder={'Enter Name'}
                onChange={(e) => setNameInput(e.target.value)}
                value={nameInput}
              />
            </div>
            <div className='mb-8'>
              <label className='form-label'>Title</label>
              <input
                type='text'
                className='form-control'
                placeholder={'Customize your Title.'}
                onChange={(e) => setTitleInput(e.target.value)}
                value={titleInput}
              />
              <div className='d-flex flex-column justify-content-start'>
                <p className='text-primary align-self-start fw-bold mt-2 mb-0 cursor-pointer' onClick={() => handleShortCode('title')}>
                  <FontAwesomeIcon icon={faPlus} className='me-2' /> See the Customization Options here
                </p>
              </div>
              {shortCodeVisibility.title ? (
                <div className='text-start'>
                  {shortCode
                    ? shortCode.map((item: any) => {
                        return (
                          <Fragment key={item.id}>
                            <CopyToClipboard text={item.name}>
                              <Button
                                size='sm'
                                variant={copyMarkup['title'] && buttonString === item.name ? 'outline-success' : 'outline-primary'}
                                className={`px-3 py-1 border border-1 m-2 ${
                                  copyMarkup['title'] && buttonString === item.name ? 'border-success' : 'border-primary'
                                } rounded text-hover-dark fs-sm-8 inline-block`}
                                onClick={() => handleCopyMarkup(item.name, 'title')}
                              >
                                {copyMarkup['title'] && buttonString === item.name ? <span className='fs-sm-8'>Copied !</span> : item.name}
                              </Button>
                            </CopyToClipboard>
                          </Fragment>
                        );
                      })
                    : null}
                </div>
              ) : null}

              <div className='d-flex flex-column justify-content-start'>
                <div>
                  <button type='button' className='btn btn-sm btn-link text-hover-primary fw-bold fs-6 m-0 p-0' onClick={()=>{ setOpenTitleAdvanceConfig(!openTitleAdvanceConfig)}} > <i className="bi bi-sliders fw-bold text-primary fs-5"></i>AI-Powered Content Optimization </button>
                </div>
              </div>
              {
                openTitleAdvanceConfig ? 
                <div className='row mt-2' >
                  <div className='col-3' >
                    <div className="ps-0 ms-2 form-check form-switch form-check-custom form-check-solid d-flex flex-row justify-content-start align-items-start">
                      <label className=" text-secondary fw-bold" htmlFor="titleCode">
                          Remove HTML  <OverlayTrigger placement='top' overlay={(props)=>renderTooltip({...props, message:'Remove html code or characters'})} >
                                            <i className="bi bi-info-circle"></i>
                                        </OverlayTrigger> 
                      </label>
                      
                      <input className="form-check-input h-20px w-40px ms-2" type="checkbox"
                        checked={titleAdvanceSetting.isRemoveHtml}
                        onChange={(ev)=>{
                          setTitleAdvanceSetting((pre)=>{
                            return {...pre, isRemoveHtml:ev.target.checked}
                          })
                        }}
                      id="titleCode"/>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="ps-0 ms-2 form-check form-switch form-check-custom form-check-solid d-flex flex-row justify-content-start align-items-start">
                      <label className="text-secondary fw-bold" htmlFor="titleTranslate">
                          Translate To Other Language <OverlayTrigger placement='top' overlay={(props)=>renderTooltip({...props, message:'Translate to other language'})} >
                                            <i className="bi bi-info-circle"></i>
                                        </OverlayTrigger> 
                      </label>
                      <input 
                          className="form-check-input h-20px w-40px ms-2" type="checkbox" value="" id="titleTranslate"
                          checked={titleAdvanceSetting.isTranslate} 
                          onChange={(ev)=>{
                            setTitleAdvanceSetting((pre)=>{
                              return {...pre, isTranslate:ev.target.checked}
                            })
                          }}
                          />
                    </div>
                    { titleAdvanceSetting.isTranslate ? 
                    <div className='ms-2 mt-2 mw-200px' >
                      <select value={titleAdvanceSetting.translate_language}  className="form-select" 
                        onChange={(ev)=>{
                          setTitleAdvanceSetting((pre)=>({
                            ...pre, translate_language:ev.target.value
                          }))
                        }}
                      >
                        <option value="">Select Language </option>
                        {
                          LANGUAGES_ENUM.map((item)=>{
                            return <option key={ "title" + item} value={item}>{item}</option>
                          })
                        }
                      </select>
                    </div> : null }
                  </div>
                  <div className="col-4">
                    <div className="ps-0 ms-2 form-check form-switch form-check-custom form-check-solid d-flex flex-row justify-content-start align-items-start">
                      <label className="text-secondary fw-bold" htmlFor="titleRephrasing">
                          Rephrasing <OverlayTrigger placement='top' overlay={(props)=>renderTooltip({...props, message:'Add a limit to word'})} >
                                            <i className="bi bi-info-circle"></i>
                                        </OverlayTrigger> 
                      </label>
                      <input className="form-check-input h-20px w-40px ms-2" type="checkbox" value="" id="titleRephrasing" 
                        checked={titleAdvanceSetting.isRephrasing} 
                        onChange={(ev)=>{
                          setTitleAdvanceSetting((pre)=>{
                            return {...pre, isRephrasing:ev.target.checked}
                          })
                        }}
                      />
                    </div>
                    {
                      titleAdvanceSetting.isRephrasing ?
                    <div className='ms-2 mt-2' >
                      <input placeholder='Enter Character Limit' 
                        type="number" 
                        required
                        className='form-control mw-200px'
                        value={titleAdvanceSetting.rephrasing_limit} 
                        onChange={(ev)=>{
                          setTitleAdvanceSetting((pre)=>{
                            return {...pre, rephrasing_limit:ev.target.value}
                          })
                        }}  
                      />
                    </div>
                    : null
                    }
                  </div>
                </div> : null
              }
            </div>
            <div>
              <label className='form-label'>Description</label>
              <AppCustomEditor value={editorData} onChangeData={setEditorData}   placeholder="Customize your Description or simply map with Description field of Primary Store by using  Options given below in plus icon. "/>
              <div className='d-flex flex-column justify-content-start'>
                <p className='text-primary align-self-start fw-bold mt-2 mb-0 cursor-pointer' onClick={() => handleShortCode('description')}>
                  <FontAwesomeIcon icon={faPlus} className='me-2' /> See the Customization Options here
                </p>
              </div>
              {shortCodeVisibility.description ? (
                <div className='text-start'>
                  {shortCode
                    ? shortCode.map((item: any) => {
                        return (
                          <Fragment key={item.id}>
                            <CopyToClipboard text={item.name}>
                              <Button
                                size='sm'
                                variant={copyMarkup['description'] && buttonString === item.name ? 'outline-success' : 'outline-primary'}
                                className={`px-3 py-1 border border-1 m-2 ${
                                  copyMarkup['description'] && buttonString === item.name ? 'border-success' : 'border-primary'
                                } rounded text-hover-dark fs-sm-8 inline-block`}
                                onClick={() => handleCopyMarkup(item.name, 'description')}
                              >
                                {copyMarkup['description'] && buttonString === item.name ? <span className='fs-sm-8'>Copied !</span> : item.name}
                              </Button>
                            </CopyToClipboard>
                          </Fragment>
                        );
                      })
                    : null}
                </div>
              ) : null}
              <div className='d-flex flex-column justify-content-start'>
                <div>
                  <button type='button' className='btn btn-sm btn-link fw-bold text-primary fs-6 m-0 p-0' onClick={()=>{ setOpenDescriptionAdvanceConfig(!openDescriptionAdvanceConfig)}} > <i className="bi bi-sliders fw-bold text-primary fs-5"></i>AI-Powered Content Optimization </button>
                </div>
              </div>
              {
                openDescriptionAdvanceConfig ? 
                <div className='row mt-2' >
                  <div className='col-3' >
                    <div className="ps-0 ms-2 form-check form-switch form-check-custom form-check-solid d-flex flex-row justify-content-start align-items-start">
                      <label className=" text-secondary fw-bold" htmlFor="descriptionCode">
                          Remove HTML  <OverlayTrigger placement='top' overlay={(props)=>renderTooltip({...props, message:'Remove html code or characters'})} >
                                            <i className="bi bi-info-circle"></i>
                                        </OverlayTrigger> 
                      </label>
                      
                      <input className="form-check-input h-20px w-40px ms-2" type="checkbox" id="descriptionCode"
                      checked={descriptionAdvanceSetting.isRemoveHtml} 
                      onChange={(ev)=>{
                        setDescriptionAdvanceSetting((pre)=>{
                          return {...pre, isRemoveHtml:ev.target.checked}
                        })
                      }}
                      />
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="ps-0 ms-2 form-check form-switch form-check-custom form-check-solid d-flex flex-row justify-content-start align-items-start">
                      <label className="text-secondary fw-bold" htmlFor="titleTranslate">
                          Translate To Other Language <OverlayTrigger placement='top' overlay={(props)=>renderTooltip({...props, message:'Translate to other language'})} >
                                            <i className="bi bi-info-circle"></i>
                                        </OverlayTrigger> 
                      </label>
                      <input 
                          className="form-check-input h-20px w-40px ms-2" type="checkbox" value="" id="titleTranslate"
                          checked={descriptionAdvanceSetting.isTranslate} 
                          onChange={(ev)=>{
                            setDescriptionAdvanceSetting((pre)=>{
                              return {...pre, isTranslate:ev.target.checked}
                            })
                          }}
                          />
                    </div>
                    { descriptionAdvanceSetting.isTranslate ? 
                    <div className='ms-2 mt-2 mw-200px' >
                      <select value={descriptionAdvanceSetting.translate_language} className="form-select"
                        onChange={(ev)=>{
                          setDescriptionAdvanceSetting((pre)=>({
                            ...pre, translate_language:ev.target.value
                          }))
                        }}
                      >
                        <option value="">Select Language </option>
                        {
                          LANGUAGES_ENUM.map((item)=>{
                            return <option key={'description'+item} value={item}>{item}</option>
                          })
                        }
                      </select>
                    </div> : null }
                  </div>
                  <div className="col-4">
                    <div className="ps-0 ms-2 form-check form-switch form-check-custom form-check-solid d-flex flex-row justify-content-start align-items-start">
                      <label className="text-secondary fw-bold" htmlFor="titleRephrasing">
                          Rephrasing <OverlayTrigger placement='top' overlay={(props)=>renderTooltip({...props, message:'Add a limit to word'})} >
                                            <i className="bi bi-info-circle"></i>
                                        </OverlayTrigger> 
                      </label>
                      <input className="form-check-input h-20px w-40px ms-2" type="checkbox" value="" id="titleRephrasing" 
                        checked={descriptionAdvanceSetting.isRephrasing} 
                        onChange={(ev)=>{
                          setDescriptionAdvanceSetting((pre)=>{
                            return {...pre, isRephrasing:ev.target.checked}
                          })
                        }}
                      />
                    </div>
                    {
                      descriptionAdvanceSetting.isRephrasing ?
                    <div className='ms-2 mt-2' >
                      <input placeholder='Enter Character Limit' type="number" value={descriptionAdvanceSetting.rephrasing_limit} 
                      className='form-control mw-200px' 
                      onChange={(ev)=>{
                        setDescriptionAdvanceSetting((pre)=>{
                          return {...pre, rephrasing_limit:ev.target.value}
                        })
                      }}
                      />
                    </div>
                    : null
                    }
                  </div>
                </div> : null
              }
            </div>
          {/* <div className='mb-8'>
            <label className='form-label'>Tags</label>
            <input
              type='text'
              className='form-control'
              placeholder={'Customize or map the Tag Field as needed.'}
              onChange={(e) => setTagsInput(e.target.value)}
              value={tagsInput}
            />
            <div className='text-end'>
              <p className='text-muted text-hover-primary fw-bold mt-1 cursor-pointer' onClick={() => handleShortCode('tags')}>
                <FontAwesomeIcon icon={faPlus} className='me-2' /> See the Options here
              </p>
            </div>
            {shortCodeVisibility.tags ? (
              <div className='text-end'>
                {shortCode
                  ? shortCode.map((item: any) => {
                      return (
                        <Fragment key={item.id}>
                          <CopyToClipboard text={item.name}>
                            <Button
                              size='sm'
                              variant={copyMarkup['tags'] && buttonString === item.name ? 'outline-success' : 'outline-primary'}
                              className={`px-3 py-2 border border-1 m-2 ${
                                copyMarkup['tags'] && buttonString === item.name ? 'border-success' : 'border-primary'
                              } rounded text-hover-dark fs-sm-8 inline-block`}
                              onClick={() => handleCopyMarkup(item.name, 'tags')}
                            >
                              {copyMarkup['tags'] && buttonString === item.name ? <span className='fs-sm-8'>Copied !</span> : item.name}
                            </Button>
                          </CopyToClipboard>
                        </Fragment>
                      );
                    })
                  : null}
              </div>
            ) : null}
          </div>
          <div className='mb-8'>
            <label className='form-label'>Sku</label>
            <input
              type='text'
              className='form-control'
              placeholder={'Enter Sku'}
              onChange={(e) => setSkuInput(e.target.value)}
              value={skuInput}
            />
          </div> */}
          </Modal.Body>
        </form>
      </Modal>
    </>
  );
};

export default AddNewTitleDescriptionModal;
