import React, {ChangeEventHandler, FC} from 'react'

interface InputProps {
  id: number
  name: string
  display_name: string
  input_type?: string
  inputValue: string
  onDataChange: (ev: React.ChangeEvent<HTMLSelectElement>) => void
  options: {label: string; value: string}[]
}

const ConnectPlatformSelectInput: FC<InputProps> = ({
  display_name,
  id,
  inputValue,
  name,
  onDataChange,
  input_type,
  options,
}) => {
  return (
    <div className='row'>
      <div className='col-md-3 col-6' >
        <label className='form-label'>{display_name}</label>
      </div>
      <div className='col-sm-9 col-12' >
        <select
          name=''
          value={inputValue}
          onChange={onDataChange}
          id=''
          className='form-control form-control-solid'
        >
          <option value=''>{display_name} </option>
          {options.length &&
            options.map((option) => {
              return <option value={option.value}>{option.label}</option>
            })}
        </select>
      </div>
    </div>
  )
}

export default ConnectPlatformSelectInput
