import React, {FC, useState} from 'react'

const Article: FC<{item: any}> = ({item}) => {
  const [isReadMoreOn, setIsReadMoreOn] = useState(false)

  return (
    <div className='d-flex flex-column '>
      <div className='d-flex justify-content-between align-items-center' >
        <span className='mb-0 text-secondary fw-bold fs-6'>{item.title}</span>
        <small className='text-end fs-8 '>
          {item.date}
        </small>
      </div>
      <div className={ !isReadMoreOn ? 'text-truncate' : '' }>
        <small className='fs-6'>{item.description}</small>
      </div>
      <button className='btn btn-sm text-primary fw-bold text-end p-0' onClick={()=>setIsReadMoreOn(!isReadMoreOn)} >{ isReadMoreOn ? 'Read Less' : 'Read More...'}</button>
    </div>
  )
}

export default Article
