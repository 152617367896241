import React, { Fragment, useEffect, useState } from 'react';


interface InputProps {
  id: number;
  name: string;
  display_name: string;
  input_type?: string;
  inputValue: string;
  onDataChange: (data: { id: number;name:string; value: string }) => void;
}

const InputFieldComponent: React.FC<InputProps> = (props) => {

  const {
    id,
    name,
    display_name,
    input_type ,
    inputValue,
    onDataChange,
  } = props;

  const [value, setValue] = useState('');

  // Update local value when input value changes
  useEffect(() => {

    setValue(inputValue);
  }, [inputValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
     const newValue = event.target.value;
    setValue(newValue);
    onDataChange({ id, name, value: newValue });
  
  };
  const inputType = input_type === 'password' ? 'password' : 'text';

  return (
    <Fragment>
      <div className='row' >
        <div className='col-md-3 col-6' >
          <label className='form-label' >
            {display_name}
          </label>
        </div>
        <div className='col-sm-9 col-12' >
          <input
            key={id}
            type={inputType}
            className="form-control form-control-solid"
            placeholder={display_name}
            value={value}
            onChange={handleChange}
            // style={{ width: '250px' }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default InputFieldComponent;
