import React, {FC} from 'react'
import {Modal} from 'react-bootstrap'
import { PlatformDataType } from 'src/interfaces/Platform.interface'

type AlertModalPropsType = {
  isVisible: boolean
  closeModal: () => void
  onDeleteConfirmed: () => void; // New prop for handling delete confirmation
  selectedPlatformDelete:PlatformDataType|undefined
  inProgress?:boolean
  successBtnLabel?:string
}

const AlertModal: FC<AlertModalPropsType> = ({isVisible, closeModal,onDeleteConfirmed,selectedPlatformDelete,inProgress=false,successBtnLabel='Confirm Delete'}) => {
  const handleDeleteConfirmed = () => {
    onDeleteConfirmed();
  };
  console.log("selected platform delete console:",selectedPlatformDelete)

  return (
    <Modal show={isVisible} centered onHide={closeModal}>
      <div>
        <div className='alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-0'>
          <button
            type='button'
            className='position-absolute top-0 end-0 m-2 btn btn-icon btn-icon-danger'
            data-bs-dismiss='alert'
          >
            <span className='svg-icon svg-icon-1'></span>
          </button>

          <span className='svg-icon svg-icon-5tx svg-icon-danger mb-5'></span>

          <div className='text-center'>
            <h5 className='fw-bolder fs-3 mb-5'>Are you sure you want to Disconnect your Store ?</h5>

            <div className='separator separator-dashed border-danger opacity-25 mb-5'></div>

            <div className='mb-9 fs-6'>
              Deleting your {selectedPlatformDelete?.isMainStore==true? "Primary": "Secondary"} Store will remove all the data logs within
              Commercium and Stop the sync process. Click on "Confirm Delete"
              please if you agree.{' '}
             
            </div>
            <div className='d-flex flex-center flex-wrap'>
              <button
                disabled={inProgress}
                className='btn btn-outline btn-outline-danger btn-active-danger m-2'
                onClick={closeModal}
              >
                Cancel
              </button>
              <button disabled={inProgress} className='btn btn-sm btn-danger m-2' onClick={handleDeleteConfirmed}>
                {successBtnLabel}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AlertModal
