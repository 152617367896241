import clsx from 'clsx'
import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import TourStep1 from './components/step-one/TourStep1'
import TourStep2 from './components/step-two/TourStep2'
import PlatformConfiguration from './components/step-three/PlatformConfiguration'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {
  fetchConnectedPlatformAsync,
  fetchPlatformsAsync,
  retrivePlatformDataAsync,
} from 'src/redux/actions/platformActions'
import Loader from 'src/utils/Loader'
import {KTSVG, toAbsoluteUrl} from 'src/marketConnect/helpers'
import { setInitialPopupDataAsync } from 'src/redux/actions/authActions'
import PopupPricing from './components/PopupPricing'

type StepStatus = 'active' | 'inactive' | 'finished'

type TourSteps = {
  stepZero?:StepStatus
  stepOne: StepStatus
  stepTwo: StepStatus
  stepThree: StepStatus
}

type TourPopupPropsType = {
  // visible:boolean
  // loading:boolean
  // stepsData:TourSteps
  // selectedPlatformId:any
  // setStepsData: Dispatch<SetStateAction<TourSteps>>
  // setVisible: Dispatch<SetStateAction<boolean>>
  // setSelectedPlatformId: Dispatch<SetStateAction<string | undefined>>

}

// const TourPopup: FC<TourPopupPropsType> = ({loading,visible, stepsData, selectedPlatformId,setStepsData,setVisible,setSelectedPlatformId }) => {
  const TourPopup: FC<TourPopupPropsType> = () => {

  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [selectedPlatformId, setSelectedPlatformId] = useState<string | undefined>(undefined)
  const dispatch = useAppDispatch()
  const notOpenInPaths = ['/handle-app-installation','/handle-oauth-callback-restricted','/platform-setting','/primary-platform-setting','/platform-setting-new']
  const {fetchedConnectedPlatformLoading} = useAppSelector((state) => state.fetchConnectedPlatform)
  const {fetchedUserAccountData } = useAppSelector(state=>state.userAccountData)
  const { userCurrentSubscription } = useAppSelector(state=>state.pricing)
  const [stepsData, setStepsData] = useState<TourSteps>({
    stepOne: 'active',
    stepTwo: 'inactive',
    stepThree: 'inactive',
  })

  console.log('tour popup------>', stepsData);
  

  useEffect(() => {
    console.log('current path-->', window.location.pathname);
    if(!notOpenInPaths.includes(window.location.pathname) && !notOpenInPaths.some(item=>(window.location.pathname.startsWith(item))) && fetchedUserAccountData && userCurrentSubscription ){
      (async function () {
        try {
          await dispatch(fetchPlatformsAsync())
          const res = await dispatch(fetchConnectedPlatformAsync())
  
          if (res.payload?.status_code == 200) {
            if (res.payload?.data?.length && res.payload?.data?.length <= 2) {
              setLoading(true)
              const isMainStoreCreated = res.payload?.data?.find((item) => item?.isMainStore == true)
              const isSecondaryStoreCreated = isMainStoreCreated && res.payload?.data?.length == 2
              //checking only main store created
              if (!isMainStoreCreated && !isSecondaryStoreCreated) {
                setVisible(true)
                setLoading(false)

              }
  
              if (isMainStoreCreated && !isSecondaryStoreCreated) {
                if(['SHOPIFY', 'WOOCOMMERCE','ECWID' ].includes(fetchedUserAccountData?.source!)){
                    if(userCurrentSubscription?.current_billing_plan){
                      setStepsData((pre) => {
                        return {...pre, stepZero:'finished', stepOne: 'finished', stepTwo: 'active'}
                      })
                    }
                }else{
                  setStepsData((pre) => {
                    return {...pre, stepOne: 'finished', stepTwo: 'active'}
                  })
                }
                setVisible(true)
                setLoading(false)
              }
  
              //checking secondary store is also created but setting not configured
              if (isMainStoreCreated && isSecondaryStoreCreated) {
                const secondaryStoreSetting = await dispatch(
                  retrivePlatformDataAsync({id: res.payload?.data[1]?.id})
                )
                setSelectedPlatformId(res.payload?.data[1]?.id)
                if (secondaryStoreSetting.payload?.platform_settings) {
                  //it means user has created secondary store and made setting also
                  await dispatch(setInitialPopupDataAsync({isPlatformInitProcessCompleted:true}))
                  setVisible(false)
                  setLoading(false)
                } else {
                  if(['SHOPIFY', 'WOOCOMMERCE','ECWID' ].includes(fetchedUserAccountData?.source!)){
                    if(userCurrentSubscription?.current_billing_plan){
                      setStepsData((pre) => {
                        return {...pre, stepZero:'finished', stepOne: 'finished', stepTwo: 'finished', stepThree:'active'}
                      })
                    }
                  }else{
                    setStepsData((pre) => {
                      return {stepOne: 'finished', stepTwo: 'finished', stepThree: 'active'}
                    })
                  }
                  setVisible(true)
                }
                setLoading(false)
              }
            } else if (res.payload?.data?.length > 2) {
              setVisible(false)
              setLoading(false)
              await dispatch(setInitialPopupDataAsync({isPlatformInitProcessCompleted:true}))
            }else{
               
              if(['SHOPIFY', 'WOOCOMMERCE','ECWID' ].includes(fetchedUserAccountData?.source!)){
                if(userCurrentSubscription?.current_billing_plan){
                  setStepsData({stepZero:'finished', stepOne:'active', stepTwo:'inactive', stepThree:'inactive'})
                }else{
                  setStepsData({stepZero:'active', stepOne:'inactive', stepTwo:'inactive', stepThree:'inactive'})
                }
              }
              setLoading(false)
              setVisible(true)
            }
          }
        } catch (error) {
          setLoading(false)
          setVisible(false)
        }
      })();
    }else{
      // alert("your current path is ====> " + window.location.pathname)
    }
  }, [window.location.pathname])

  return (
    <Modal show={visible} scrollable size='xl' backdropClassName='bg-dark' className='mt-sm-0 mt-16'   >
      { loading ? (
        <Loader />
      ) : (
        <>
          <Modal.Header className='p-0 m-0 border-0'>
            {
              ['SHOPIFY', 'WOOCOMMERCE','ECWID' ].includes(fetchedUserAccountData?.source!) ? 
              <>
                <div className='w-100 ' >
                    <div className='alert bg-light-primary d-flex flex-column flex-sm-row w-100 m-0'>
                      <div className='d-flex align-items-sm-center w-100'>
                          <div
                                className={clsx(
                                  'd-flex align-items-center text-center',
                                  stepsData.stepZero == 'active'
                                    ? 'text-primary'
                                    : stepsData.stepZero == 'finished'
                                    ? 'text-success'
                                    : ''
                                )}
                                style={{width:'20%'}}
                              >
                                {
                                  stepsData.stepZero==='finished'? 
                                  <i className={ clsx( "bi bi-check-circle-fill fs-2x me-2")} style={{color:'#07bc0c'}} ></i>
                                  : <i className={ clsx( "bi bi-coin fs-2x me-2" , stepsData.stepZero==='active'? "text-primary" : "text-secondary")} ></i>
                                  
                                }
                                {/* <KTSVG path="media/icons/duotune/coding/cod008.svg" className={ stepsData.stepOne==='finished'? "svg-icon-success svg-icon-2hx" : "svg-icon-primary svg-icon-2hx"} /> */}
                                <small className='fs-sm-6 fs-8 fw-bold lh-1'>Select Your Plan</small>
                                
                          </div>
                        
                        <div className='separator w-25 border-2 border-light-secondary mx-3 d-sm-block d-none'></div>

                          <div
                            className={clsx(
                              'd-flex align-items-center text-center',
                              stepsData.stepOne == 'active'
                                ? 'text-primary'
                                : stepsData.stepOne == 'finished'
                                ? 'text-success'
                                : 'text-secondary'
                            )}
                            
                            style={{width:'28%'}}
                          >
                            {
                              stepsData.stepOne==='finished'? 
                              <i className={ clsx( "bi bi-check-circle-fill fs-2x me-2")} style={{color:'#07bc0c'}} ></i>
                              : <i className={ clsx( "bi bi-1-circle-fill fs-2x me-2" , stepsData.stepOne==='active'? "text-primary" : "text-secondary")} ></i>
                              
                            }
                            {/* <KTSVG path="media/icons/duotune/coding/cod008.svg" className={ stepsData.stepOne==='finished'? "svg-icon-success svg-icon-2hx" : "svg-icon-primary svg-icon-2hx"} /> */}
                            <small className='fs-sm-6 fs-8 fw-bold lh-1'>Connect Primary Channel</small>
                            
                          </div>
                        
                        <div className='separator w-25 border-2 border-light-secondary me-5 d-sm-block d-none'></div>
                          <div
                            className={clsx(
                              'd-flex align-items-center text-center',
                              stepsData.stepTwo == 'active'
                                ? 'text-primary'
                                : stepsData.stepTwo == 'finished'
                                ? 'text-success'
                                : 'text-secondary'
                            )}
                            style={{width:'30%'}}
                          >
                            {
                              stepsData.stepTwo=='finished' ?
                              <i className={ clsx( "bi bi-check-circle-fill fs-2x me-2")} style={{color:'#07bc0c'}} ></i>
                              :
                              <i className={ clsx( "bi bi-2-circle-fill fs-2x me-2" , stepsData.stepTwo=='inactive' ? "text-secondary" : stepsData.stepTwo==='active' ? "text-primary" : "text-secondary" )} ></i>
                            }
                            {/* <KTSVG path="media/icons/duotune/coding/cod007.svg" className={ stepsData.stepTwo=='inactive' ? "svg-icon-muted svg-icon-2hx" : stepsData.stepTwo==='active' ? "svg-icon-primary svg-icon-2hx" : "svg-icon-success svg-icon-2hx"  }  /> */}
                            <small className='fs-sm-6 fs-8 fw-bold lh-1'>Connect Secondary Channel</small>
                            
                          </div>
                        
                        <div className='separator w-25 border-2 border-light-secondary me-5 d-sm-block d-none'></div>
                          <div
                            className={clsx(
                              'd-flex align-items-center text-center',
                              stepsData.stepThree == 'active'
                                ? 'text-primary'
                                : stepsData.stepThree == 'finished'
                                ? 'text-success'
                                : 'text-secondary'
                            )}
                            style={{width:'30%'}}
                          >
                            {
                              stepsData.stepThree=='finished' ?
                              <i className={ clsx( "bi bi-check-circle-fill fs-2x me-2")} style={{color:'#07bc0c'}} ></i>
                              :
                            <i className={ clsx( "bi bi-3-circle-fill fs-2x me-2" , stepsData.stepThree=='inactive' ? "text-secondary" : stepsData.stepThree==='active' ? "text-primary" : "text-secondary" )} ></i>
                            }
                            {/* <KTSVG path="media/icons/duotune/coding/cod001.svg" className={ stepsData.stepThree=='inactive' ? "svg-icon-muted svg-icon-2hx" : stepsData.stepThree==='active' ? "svg-icon-primary svg-icon-2hx" : "svg-icon-success svg-icon-2hx"  } /> */}
                            <small className='fs-sm-6 fs-8 fw-bold lh-1'>Complete Settings</small>
                            
                          </div>
                        
                      </div>
                    </div>
                  
                </div>
              </> : 
              <>
                <div className='w-100 ' >
                    <div className='alert bg-light-primary d-flex flex-column flex-sm-row w-100 m-0'>
                      <div className='d-flex align-items-sm-center w-100'>
                          <div
                            className={clsx(
                              'd-flex align-items-center w-auto',
                              stepsData.stepOne == 'active'
                                ? 'text-primary'
                                : stepsData.stepOne == 'finished'
                                ? 'text-success'
                                : ''
                            )}
                            style={{width:'15%'}}
                          >
                            {
                              stepsData.stepOne==='finished'? 
                              <i className={ clsx( "bi bi-check-circle-fill fs-2x")} style={{color:'#07bc0c'}} ></i>
                              : <i className={ clsx( "bi bi-1-circle-fill fs-2x " , stepsData.stepOne==='active'? "text-primary" : "text-secondary")} ></i>
                              
                            }
                            {/* <KTSVG path="media/icons/duotune/coding/cod008.svg" className={ stepsData.stepOne==='finished'? "svg-icon-success svg-icon-2hx" : "svg-icon-primary svg-icon-2hx"} /> */}
                            <small className='fs-sm-6 fs-8 fw-bold lh-1 text-center'>Connect Primary Channel</small>
                            
                          </div>
                        
                        <div className='separator w-25 border-2 border-light-secondary me-5 d-sm-block d-none'></div>
                          <div
                            className={clsx(
                              'd-flex align-items-center w-auto',
                              stepsData.stepTwo == 'active'
                                ? 'text-primary'
                                : stepsData.stepTwo == 'finished'
                                ? 'text-success'
                                : 'text-secondary'
                            )}
                            style={{width:'15%'}}
                          >
                            {
                              stepsData.stepTwo=='finished' ?
                              <i className={ clsx( "bi bi-check-circle-fill fs-2x")} style={{color:'#07bc0c'}} ></i>
                              :
                              <i className={ clsx( "bi bi-2-circle-fill fs-2x" , stepsData.stepTwo=='inactive' ? "text-secondary" : stepsData.stepTwo==='active' ? "text-primary" : "text-secondary" )} ></i>
                            }
                            {/* <KTSVG path="media/icons/duotune/coding/cod007.svg" className={ stepsData.stepTwo=='inactive' ? "svg-icon-muted svg-icon-2hx" : stepsData.stepTwo==='active' ? "svg-icon-primary svg-icon-2hx" : "svg-icon-success svg-icon-2hx"  }  /> */}
                            <small className='fs-sm-6 fs-8 fw-bold lh-1 text-center'>Connect Secondary Channel</small>
                            
                          </div>
                        
                        <div className='separator w-25 border-2 border-light-secondary me-5 d-sm-block d-none'></div>
                          <div
                            className={clsx(
                              'd-flex align-items-center w-auto',
                              stepsData.stepThree == 'active'
                                ? 'text-primary'
                                : stepsData.stepThree == 'finished'
                                ? 'text-success'
                                : 'text-secondary'
                            )}
                            style={{width:'15%'}}
                          >
                            {
                              stepsData.stepThree=='finished' ?
                              <i className={ clsx( "bi bi-check-circle-fill fs-2x me-2")} style={{color:'#07bc0c'}} ></i>
                              :
                            <i className={ clsx( "bi bi-3-circle-fill fs-2x me-2" , stepsData.stepThree=='inactive' ? "text-muted" : stepsData.stepThree==='active' ? "text-primary" : "text-secondary" )} ></i>
                            }
                            {/* <KTSVG path="media/icons/duotune/coding/cod001.svg" className={ stepsData.stepThree=='inactive' ? "svg-icon-muted svg-icon-2hx" : stepsData.stepThree==='active' ? "svg-icon-primary svg-icon-2hx" : "svg-icon-success svg-icon-2hx"  } /> */}
                            <small className='fs-sm-6 fs-8 fw-bold lh-1 text-center'>Complete Settings</small>
                            
                          </div>
                        
                      </div>
                    </div>
                  
                </div>
              </>
            }
          </Modal.Header>
          <Modal.Body>
          { ['SHOPIFY', 'WOOCOMMERCE','ECWID' ].includes(fetchedUserAccountData?.source!) && 
            !userCurrentSubscription?.current_billing_plan && stepsData?.stepZero === 'active' ? (
              <PopupPricing 
                setStepsData={setStepsData}
              />
            ) : null}
            
            {stepsData.stepOne === 'active' ? (
              <TourStep1
                setStepsData={setStepsData}
                setSelectedPlatformId={setSelectedPlatformId}
              />
            ) : null}

            {stepsData.stepTwo === 'active' ? (
              <TourStep2
                setStepsData={setStepsData}
                setSelectedPlatformId={setSelectedPlatformId}
              />
            ) : null}

            {stepsData.stepThree === 'active' ? (
              <PlatformConfiguration selectedPlatformId={selectedPlatformId} afterSettingCB={()=>{
                setStepsData({
                  stepOne:'finished',
                  stepTwo:'finished',
                  stepThree:'finished'
                })
                setVisible(false)
              }} />
            ) : null}
            <section></section>
          </Modal.Body>

        </>
      )}
    </Modal>
  )
}

export default TourPopup
