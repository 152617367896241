import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {getOrderDetailsAsync} from 'src/redux/actions/orderActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'

import OrderSummary1 from './components/OrderSummary1'
import Loader from 'src/utils/Loader'

const OrderDetails = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const {loadingOrderDetails, orderDetails} = useAppSelector((state) => state.orderDetailsData)

  const {orderId} = useParams()

  useEffect(() => {
    // Call the getOrderDetailsFromId function
    if(orderId){
      setLoading(true)
      dispatch(getOrderDetailsAsync(orderId))
        .then(() => {
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [orderId])

  // ... rest of your component code

   console.log('loading', loading)

  return (
    <div>
      <h1 className='page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0'>
        Order Details
      </h1>
      <div className='separator my-4'></div>
     { loading ? <Loader/>
      :
      <OrderSummary1 />
     }
    </div>
  )
}

export default OrderDetails
