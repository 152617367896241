import React, {FC, useState} from 'react'
import DataTable,{TableColumn} from 'react-data-table-component'
import {KTIcon} from 'src/marketConnect/helpers'
import icons from 'src/marketConnect/helpers/icons-config/icons'
import { AckActionBtn } from './components/AckActionBtn'

const CancellationLogs: FC = () => {


  const columns:TableColumn<any>[] = [
    {
      name: 'Channel Order #',
      cell: (row) => row.orderNumber,
    },
    {
      name: 'Main Store Order #',
      cell: (row) => row.customerName,
    },
    {
      name: 'Cancel Date/Time',
      cell: (row) => row.orderedDate,
    },
    
    {
      name: 'Cancel Status',
      cell: (row) =>
        {
          if(row.shippingStatus==='Cancelled'){
            return(<span className='badge badge-light-danger text-danger fs-6 px-3 py-2 w-75 ' >{row.shippingStatus}</span>)
          }else{
            return(<span className='badge badge-light-success text-success fs-6 px-3 py-2 w-75 ' >{row.shippingStatus}</span>)
          }
        },
    },
    {
      name: 'Channel',
      cell: (row) => row.unitQuantity,
      
    },
    
    {
      name: 'Action',
      cell:()=><AckActionBtn/>,
       
    },
  ]

  const orders = [
    {
      orderNumber: 'ORD12345',
      customerName: 'John Doe',
      productName: 'Wireless Mouse',
      orderedDate: '2024-10-10 12:04 PM',
      unitQuantity: 'SHOPIFY',
      shippingStatus: 'Cancelled',
      paymentStatus: 'Paid',
      totalAmount: '$25.99',
      action: 'View',
    },
    {
      orderNumber: 'ORD12346',
      customerName: 'Jane Smith',
      productName: 'Bluetooth Headphones',
      orderedDate: '2024-10-11',
      unitQuantity: 'RANGE',
      shippingStatus: 'Processing',
      paymentStatus: 'Pending',
      totalAmount: '$49.99',
      action: 'View',
    },
    {
      orderNumber: 'ORD12347',
      customerName: 'Alex Johnson',
      productName: 'Smartphone Case',
      orderedDate: '2024-10-12',
      unitQuantity: 'EQWUIT',
      shippingStatus: 'Cancelled',
      paymentStatus: 'Paid',
      totalAmount: '$15.00',
      action: 'View',
    },
    {
      orderNumber: 'ORD12348',
      customerName: 'Emily Davis',
      productName: 'USB-C Charger',
      orderedDate: '2024-10-13',
      unitQuantity: 'KOGAN',
      shippingStatus: 'Cancelled',
      paymentStatus: 'Paid',
      totalAmount: '$19.99',
      action: 'View',
    },
    {
      orderNumber: 'ORD12349',
      customerName: 'Michael Brown',
      productName: 'Laptop Stand',
      orderedDate: '2024-10-14',
      unitQuantity: 'SHOPIFY',
      shippingStatus: 'Not Cancelled',
      paymentStatus: 'Refunded',
      totalAmount: '$40.00',
      action: 'View',
    },
    {
      orderNumber: 'ORD12350',
      customerName: 'Sarah Wilson',
      productName: 'Gaming Keyboard',
      orderedDate: '2024-10-15',
      unitQuantity: 'KOGAN',
      shippingStatus: 'Cancelled',
      paymentStatus: 'Paid',
      totalAmount: '$75.00',
      action: 'View',
    },
    {
      orderNumber: 'ORD12351',
      customerName: 'David Martinez',
      productName: '4K Monitor',
      orderedDate: '2024-10-16',
      unitQuantity: 'SHOPIFY',
      shippingStatus: 'Cancelled',
      paymentStatus: 'Paid',
      totalAmount: '$299.99',
      action: 'View',
    },
    {
      orderNumber: 'ORD12352',
      customerName: 'Sophia Thompson',
      productName: 'External Hard Drive',
      orderedDate: '2024-10-17',
      unitQuantity: 'RANGE',
      shippingStatus: 'Cancelled',
      paymentStatus: 'Pending',
      totalAmount: '$59.99',
      action: 'View',
    },
    {
      orderNumber: 'ORD12353',
      customerName: 'James Anderson',
      productName: 'Wireless Keyboard',
      orderedDate: '2024-10-18',
      unitQuantity: 'SHOPIFY',
      shippingStatus: 'Delivered',
      paymentStatus: 'Paid',
      totalAmount: '$35.00',
      action: 'View',
    },
    {
      orderNumber: 'ORD12354',
      customerName: 'Olivia Garcia',
      productName: 'Smartwatch',
      orderedDate: '2024-10-19',
      unitQuantity: 'SHOPIFY',
      shippingStatus: 'Processing',
      paymentStatus: 'Pending',
      totalAmount: '$199.99',
      action: 'View',
    },
  ]

  return (
    <div>
      <span className='fw-bold text-lg' style={{fontSize: '16px'}}>
        Order Cancellation
      </span>
      <div className='card card-custom mt-2 shadow border-0   '>
        <div className='card-header bg-white '>
          <div className='card-title'>
            <input type='text' className='form-control border-2 p-2 fs-6 shadow-sm' placeholder='Search' />
          </div>
          <div className='card-toolbar min-h-0'>
            <div className='d-sm-flex justify-content-between align-items-center'>
              <select className='form-select fs-6 min-w-200px shadow-sm' style={{padding: '8px'}}>
                <option value=''>Select Status</option>
              </select>
              <button className='btn btn-sm btn-primary fw-bold fs-6 min-w-200px mx-sm-2 my-2 my-sm-0 shadow'>
                Pick a Date Range
              </button>
              <button className='btn btn-sm btn-warning fw-bold fs-6 shadow'>Reset</button>
            </div>
          </div>
        </div>

        <div className='card-body'>
          <DataTable
            columns={columns}
            data={orders}
            fixedHeader
            persistTableHead
            responsive
            pagination
            paginationServer
            customStyles={{
              table: {
                style: {
                  height: '48vh',
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default CancellationLogs
