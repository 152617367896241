import moment from 'moment'
import React, {FC, useEffect, useState} from 'react'
import DataTable from 'react-data-table-component'
import useAppToast from 'src/hooks/useAppToast'
import AppDatePickerRange from '../orders/components/AppDatePickerRange'
import {Spinner} from 'react-bootstrap'
import StatusButton from '../orders/StatusButton'
import StatusButtonAdmin from './StatusButtonAdmin'
import {KTSVG} from 'src/marketConnect/helpers'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'
import { getCustomerReportAsync, getCustomersAsync } from 'src/redux/actions/customerAction'
import { CUSTOMER } from 'src/app/_interfaces/customer.interface'
import Select from 'react-select'
import Loader from 'src/utils/Loader'
import { fetchConnectedPlatformAsync, fetchPlatformsAsync } from 'src/redux/actions/platformActions'
import { setSelectedCustomerForReport } from 'src/redux/slices/customerSlice'
import { Platform } from 'src/interfaces/Platform.interface'

const Report:FC = () => {
  const [paginationData, setPaginationData] = useState({
    // page_num: 1,
    // page_limit: 10,
    // search_string: '',
    // sync_status: '',
    // shipment_status: '',
    // sort_by: 'api_created_at',
    // sort_order: 'DESC',
    platform_name:'',
    from_date: '',
    to_date: '',

  })

  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [showDateRangePicker, setShowDateRangePicker] = useState(false)
  const { customerList, totalCustomer, customerLoader, reportData, reportLoader, selectedCustomerForReport, } = useAppSelector(state=>state.customer)
  const [customers, setCustomers] = useState<CUSTOMER[]|[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const {fetchedConnectedPlatformData, fetchedConnectedPlatformLoading } = useAppSelector((state) => state.fetchConnectedPlatform)
  const { platformData, platformLoading} = useAppSelector(state=>state.platform)
  const [selectedPlatform, setSelectedPlatform] = useState<Platform|null>(null)


  // ?page_limit=10&page=1&month=march&year=2024&type=ORDER_COUNT&order_by=-id&filter=2024

  const [customerListPagination, setCustomerListPagination] = useState({
    page_limit: 10,
    page_num: 1,
    order_by: '-id',
    search: '',
    user_type: 'USER',
    from_date: '',
    to_date: '',
  })

  const handleResetFilters = () => {
    setPaginationData({
      // page_num: 1,
      // page_limit: 10,
      // search_string: '',
      // sync_status: '',
      // shipment_status: '',
      // sort_by: '',
      // sort_order: 'DESC',
      platform_name:'',
      from_date: '',
      to_date: '',
    })
    setCustomerListPagination({
      page_limit: 10,
      page_num: 1,
      order_by: '-id',
      search: '',
      user_type: 'USER',
      from_date: '',
      to_date: '',
    })
    setCurrentPage(1)
    dispatch(setSelectedCustomerForReport(null))
    setSelectedPlatform(null)
  }


  useEffect(() => {
    console.log('params of page----->', params.userId);
    if(params.userId && !isNaN(Number(params.userId)) ){
      dispatch(getCustomerReportAsync({user_id:Number(params.userId), ...paginationData }))
      .then((response)=>{
        console.log('report data of user', response.payload);
      })
    }  
  }, [params, paginationData ])

  useEffect(() => {
    if (fetchedConnectedPlatformData && fetchedConnectedPlatformData.length > 0) {
      const primaryStore: any = fetchedConnectedPlatformData.filter(
        (platform) => platform.isMainStore
      )
      const secondaryStore: any = fetchedConnectedPlatformData.filter(
        (platform) => !platform.isMainStore
      )

      // setPrimaryStorePlatforms(primaryStore)
      // setSecondaryStorePlatforms(secondaryStore)
    }
  }, [fetchedConnectedPlatformData])

  useEffect(() => {
    dispatch(getCustomersAsync({...customerListPagination}))
    .then((res)=>{
      setCustomers(res.payload?.data?.data)
    })
  }, [customerListPagination])

  useEffect(() => {
    dispatch(fetchPlatformsAsync())
    // dispatch(fetchConnectedPlatformAsync())
    if(!selectedCustomerForReport){
      navigate('/reports')
    }

  }, [ ])

 
  // if(reportLoader) return <Loader/>

  return (
    <div>
      <div className='mb-3'>
        <span className='fw-bold text-lg' style={{fontSize: '16px'}}>
          Sales Orders Reports
        </span>
      </div>
      <div className='card'>
        <div className='card-header'>
          
          <div className='card-toolbar w-100 justify-content-sm-end'>
            <div className='d-flex flex-sm-row flex-column justify-content-end w-100'>
              <div className='mx-md-2 my-1 my-md-0' style={{width:"160px"}} >
                <Select
                 placeholder='Select User'
                 value={selectedCustomerForReport}
                 options={customers}
                 isLoading={customerLoader}
                 getOptionLabel={(item)=>item.name ? item.name : item.email}
                 getOptionValue={(item)=>item.id+''}
                 isSearchable
                 styles={{
                    dropdownIndicator : (baseStyles)=>{
                     return {...baseStyles, display:'none'}
                    },
                    indicatorSeparator : (baseStyles)=>{
                      return {...baseStyles, display:'none'}
                    }
                  }}
                  onInputChange={(ev)=>{
                    console.log('-------------->>>', ev);
                    if(ev){
                      dispatch(getCustomersAsync({...customerListPagination, page_num:1, search:ev }))
                        .then((res)=>{
                            setCustomers(res.payload?.data?.data)
                        })        
                    }
                  }}
                 onChange={(ev)=>{
                  if(ev){
                    navigate(`/reports/${ev?.id}`)
                    dispatch(setSelectedCustomerForReport(ev))
                    // dispatch(getCustomerReportAsync({user_id:Number(ev?.id)}))
                    // .then((response)=>{
                    //   console.log('report data of user', response.payload);
                    // })
                  }
                 }}
                 onMenuScrollToBottom={(ev)=>{
                  if(currentPage < totalCustomer/10 ){
                    dispatch(getCustomersAsync({...customerListPagination, page_num: currentPage+1 }))
                    .then((res)=>{
                        console.log('res', res.payload?.data?.data?.length)
                        if(res.payload?.data?.data?.length){
                          setCurrentPage(currentPage+1)
                        }
                        setCustomers((pre)=>([...pre, ...res.payload?.data?.data]))
                    })                  
                  }
                 }}
                />
                
              </div>
              <div className='mx-md-2 my-1 my-md-0' style={{width:"160px"}} >
                <Select
                 placeholder='Select Platform'
                 isDisabled={selectedCustomerForReport===null}
                 options={platformData}
                 isLoading={platformLoading}
                 value={selectedPlatform}
                 getOptionLabel={(item)=>item.display_name ? item.display_name : item.name}
                 getOptionValue={(item)=>item.id+''}
                 isSearchable
                 styles={{
                    dropdownIndicator : (baseStyles)=>{
                     return {...baseStyles, display:'none'}
                    },
                    indicatorSeparator : (baseStyles)=>{
                      return {...baseStyles, display:'none'}
                    }
                  }}
                  onChange={(ev)=>{
                    console.log('ev', ev)
                    if(ev){
                      setPaginationData((pre)=>({...pre, platform_name:ev.name!}))
                      setSelectedPlatform(ev)
                    }
                  }}
                />
                
              </div>
              <div className='mx-md-2 my-1 my-md-0' style={{ maxWidth:'180px'}}>
                <button
                  disabled={selectedCustomerForReport===null}
                  className='form-control rounded input me-2 h-100 py-3 py-sm-0'
                  // style={{height: '100%', padding: '0.5rem 1rem'}}
                  onClick={() => setShowDateRangePicker(!showDateRangePicker)}
                >
                  {paginationData.from_date && paginationData.to_date
                    ? `${moment(paginationData.from_date).format('YYYY/MM/DD')}  -  ${moment(
                        paginationData.to_date
                      ).format('YYYY/MM/DD')}`
                    : 'Pick a Date Range'}
                </button>
                <AppDatePickerRange
                  isVisible={showDateRangePicker}
                  handleClose={() => setShowDateRangePicker(false)}
                  onPickRange={({startDate, endDate}) => {
                    setPaginationData((pre) => ({
                      ...pre,
                      from_date: moment(startDate).format('YYYY-MM-DD'),
                      to_date: moment(endDate).format('YYYY-MM-DD'),
                      page: 1,
                    }))
                  }}
                />
              </div>
              <div className='mx-md-2 my-1 my-md-0'>
                <button
                  className='btn fs-8 btn-sm btn-primary fw-bold h-100'
                  onClick={handleResetFilters}
                >
                  Reset Filters
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body" style={{minHeight:'62vh'}} >
          {
            params?.userId == undefined ?

            <div className="row">
              <div className="alert bg-light-primary d-flex align-items-center p-5 mb-10">
                <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">
                  <KTSVG path="/media/icons/duotune/general/gen004.svg" className="svg-icon-muted svg-icon-3hx" />
                </span>
                
                <div className="d-flex flex-column">
                  <h5 className="mb-1"></h5>
                  <span className='h4 text-secondary' >Please select one <span className='text-dark ' >Customer</span> and <span className='text-dark' >Platform</span> from the Filter to See the Sales Report.</span>
                </div>
              </div>
            </div>
            :
            <div className='row g-3 g-xl-3 '>
              { reportLoader ? <Loader/> : 
                reportData.length ?
                reportData.map((report, index)=>{
                  return(
                          <div key={report.platform_id+index} className='col-lg-4 col-md-6 col-12'>
                            <div className='card border-0 h-100'>
                              <div className='card-body p-4 shadow'>
                                <div className='d-flex align-items-center justify-content-between'>
                                  <p className='fs-4 fw-bold text-secondary mb-0'>{report.platform_name}</p>
                                  <img
                                    src={report.image}
                                    alt={'Platform Logo'+report.platform_id}
                                    className='h-40px px-3 py-1'
                                    style={{objectFit:"contain"}}
                                  />
                                </div>
                                <div className='separator my-2'></div>
                                <div>
                                  <div className='mt-2 d-flex align-items-center '>
                                    <i className="bi bi-bag-check-fill text-success fs-4"></i>&nbsp;&nbsp; <p className='mb-0 fw-bold text-secondary fs-5' >Synced Orders :</p>  <span className='badge fs-5 bg-light-success text-success ms-auto'>{report.synced_order_count}</span>
                                  </div>
                                  <div className='mt-2 d-flex align-items-center '>
                                  <i className="bi bi-bag-check-fill text-success fs-4"></i>&nbsp;&nbsp; <p className='mb-0 fw-bold text-secondary fs-5' >Total Orders :</p> <span className='badge fs-5 bg-light-primary text-primary ms-auto'>{report.order_count}</span>
                                  </div>
                                  <div className='separator my-2'></div>
                                  <div className='mt-2 d-flex align-items-center '>
                                  <i className="bi bi-box-fill text-primary fs-4"></i>&nbsp;&nbsp;<p className='mb-0 fw-bold text-secondary fs-5' >Synced Products :</p> <span className='badge fs-5 bg-light-success text-success ms-auto'>{report.synced_product_count}</span>
                                  </div>
                                  <div className='mt-2 d-flex align-items-center '>
                                  <i className="bi bi-box-fill text-primary fs-4"></i>&nbsp;&nbsp; <p className='mb-0 fw-bold text-secondary fs-5' >Total Product :</p> <span className='badge fs-5 bg-light-primary text-primary ms-auto'>{report.product_count}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                  )
                }) : <p>No reports found</p>
              }
            </div>
          }
        </div>
       
      </div>
    </div>
  )
}

export default Report
